<template>
  <v-container class="bl_checkedListPage pa-0 ma-0">
    <v-tabs grow 
    height="70px"
    center-active color="grey" slider-color="primary" class="pt-2 bl_tabs">
      <v-tab v-for="tab of tabs" :key="tab.id" :to="tab.route" exact class="button text-subtitle-2 font-weight-bold primary--text"
      :class="{ 'active-class' : tab.id=='3'}"  style="flex-direction: column; align-items:center; justify-content: center; font-size:1rem !important; border-bottom: #efefef solid 3px;" disabled>
        {{ tab.name }}<br />
        <span v-if="tab.name=='建て方選択' && selectedHouseholdType" class="bl_tabs_tab_selected">{{selectedHouseholdType}}</span>
        <span v-if="tab.name=='報告方法選択' && selectedReportType" class="bl_tabs_tab_selected">{{selectedReportType}}</span>
        <span v-if="tab.name=='ファイルアップロード' && selectedReportType&& fileCnt !== 0" class="bl_tabs_tab_selected">合計 {{ fileCnt }} ファイル</span>
        <div v-if="tab.id=='3'" class="uq_badgeChecked">通過済み一覧</div>
      </v-tab>
    </v-tabs>
    <v-container class="px-8">
      <v-row class="pt-8 pb-0 px-4">
        <v-col cols=12 class="pa-0">
          <p
            class="text-h5 el_centered_h5">
            チェック通過済みファイル一覧</p>
        </v-col>
      </v-row>
      <v-row class="px-3 pb-2" style="display:flex; align-items: flex-end;">
        <v-col cols="12" md="8" class="d-flex align-end justify-center mb-2">
          <v-row>
            <v-col cols="12" align="start" class="pa-0">
              <v-card
              outlined
              max-width="820"
              >
                  <v-card-text
                      class="text-body-2 mt-4 mb-4 mx-0"
                  >
                      <v-row>
                          <v-col cols="12" sm="4">
                              <v-row justify="center">ファイル数</v-row>
                              <v-row
                                  class="pt-2"
                                  justify="center"
                                  align="center"
                              >
                                  <span class="text-h5 pr-2"> {{ correctCnt }}</span>
                                  ファイル
                              </v-row>
                          </v-col>
                          <v-divider class="hidden-sm-and-up"></v-divider>
                          <v-divider vertical class="d-none d-sm-flex"></v-divider>
                          <v-col cols="12" sm="4">
                              <v-row justify="center">住戸数</v-row>
                              <v-row
                                  class="pt-2"
                                  justify="center"
                                  align="center"
                              >
                                  <span class="text-h5 pr-2">{{ householdCnt }}</span>
                                  戸
                              </v-row>
                          </v-col>
                          <v-divider class="hidden-sm-and-up"></v-divider>
                          <v-divider vertical class="d-none d-sm-flex"></v-divider>
                          <v-col cols="12" sm="4">
                              <v-row justify="center">（参考値）平均BEI<sub>※</sub></v-row>
                              <v-row
                              class="pt-2"
                              justify="center"
                              align="center"
                              >
                              <span class="text-h5">{{ averageBEI | setFractionDigit(2) }}</span>
                              </v-row>
                          </v-col>
                      </v-row>
                  </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="4" class="pl-6 pr-0 pb-2 uq_sm_flex_row" style="display: flex; flex-direction: column; align-items: flex-end; justify-content:flex-end;">
            <small class="mb-4" syle="color: #777;">※このプログラムは住宅トップランナー制度の適合不適合を判断するものではない旨ご了承ください。</small>
            <v-spacer></v-spacer>
            <v-btn
            @click="downloadCorrectLists"
            width="250"
            height="36"
            color="#5E539A"
            >
            <span class="text-subtitle-2 font-weight-bold white--text">一覧ダウンロード</span>
            </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-0" style="min-height: calc(100vh - 300px - 5rem);">
        <v-col cols="12" align="start">
          <v-data-table
            fixed-header
            style="border:1px solid #5E539A;"
            class="bl_resultListTable"
            :items="correctResults"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-header
            hide-default-footer
            @page-count="pageCount = $event">
            <template v-slot:header>
              <thead class="text-left bg_pale" style="font-size: 0.7em !important;">
                <tr>
                  <td colspan="3" style="text-align:center; border-right: 1px solid #ccc;"></td>
                  <td colspan="2" class="primary--text" style="text-align:center; border-right: 1px solid #ccc;">
                    <small style="font-weight: 600;">外皮性能</small></td>
                  <td colspan="5" class="primary--text" style="text-align:center; border-right: 1px solid #ccc;">
                    <small style="font-weight: 600;">一次エネルギー消費性能</small></td>
                </tr>
                <tr class="primary--text">
                  <td class="text-no-wrap">No.</td>
                  <td class="width:50%; min-width:12em;">
                      <span class="text-no-wrap">PDFファイル名</span><br />
                      <small>住宅タイプの名称</small>
                  </td>
                  <td class="text-no-wrap" style="min-width:2.5em; text-align:center; border-right: 1px solid #ccc;">戸数</td>
                  <td style="min-width:3.5em; max-width:4em; text-align:center;">
                      <span class="text-no-wrap">外皮平均</span><br />
                      <span class="text-no-wrap">熱貫流率</span></td>
                  <td style="max-width:6em; text-align:center; border-right: 1px solid #ccc;">
                      <span class="text-no-wrap">冷房期の</span>
                      <span class="text-no-wrap">平均</span>
                      <span class="text-no-wrap">日射熱</span>
                      <span class="text-no-wrap">取得率</span>
                  </td>
                  <td style="max-width:5em; text-align:center;">
                    <span class="text-no-wrap">設計一次</span>
                    <span class="text-no-wrap">エネルギー</span>
                    <span class="text-no-wrap">消費量</span></td>
                  <td style="max-width:5em; text-align:center;">
                    <span class="text-no-wrap">基準一次</span>
                    <span class="text-no-wrap">エネルギー</span>
                    <span class="text-no-wrap">消費量</span>
                  </td>
                  <td style="max-width:6em; text-align:center;">
                      <span class="text-no-wrap">設計一次</span>
                      <span class="text-no-wrap">エネルギー</span>
                      <span class="text-no-wrap">消費量</span><br />
                      <small>
                        <span class="text-no-wrap">（その他の設計</span>
                        <span class="text-no-wrap">一次エネルギー</span>
                        <span class="text-no-wrap">消費量を除く）</span>
                      </small>
                  </td>
                  <td style="max-width:6em; text-align:center;">
                      <span class="text-no-wrap">基準一次</span>
                      <span class="text-no-wrap">エネルギー</span>
                      <span class="text-no-wrap">消費量</span><br />
                      <small>
                        <span class="text-no-wrap">（その他の基準</span>
                        <span class="text-no-wrap">一次エネルギー</span>
                        <span class="text-no-wrap">消費量を除く）</span>
                      </small>
                  </td>
                  <td class="text-no-wrap" style="max-width:3em; text-align:center;">BEI</td>
                </tr>
                <tr class="primary--text">
                  <td colspan="2"></td>
                  <td style="text-align:center; border-right: 1px solid #ccc;">
                    <small>[戸]</small>
                  </td>
                  <td style="text-align:center;">
                      <small>[W/m<sup>2</sup>K]</small>
                  </td>
                  <td style="text-align:center; border-right: 1px solid #ccc;">
                    [-]
                  </td>
                  <td style="text-align:center;">
                      <small>[GJ]</small>
                  </td>
                  <td style="text-align:center;">
                      <small>[GJ]</small>
                  </td>
                  <td  style="text-align:center;">
                      <small>[GJ]</small>
                  </td>
                  <td style="text-align:center;">
                      <small>[GJ]</small>
                  </td>
                  <td style="text-align:center;"></td>
                </tr>
              </thead>
            </template>
            <template v-slot:body="{ items }">
              <tbody class="text-left">
                <tr
                  v-for="(result, index) in items"
                  :key="index"
                >
                  <td> {{ (itemsPerPage * (page - 1) ) + index + 1 }}
                  <td>
                      {{ result.originalName }}<br />
                      <small style="opacity: .85;">{{ result.household_name }}</small>
                  </td>
                  <td style="text-align:center; border-right: 1px solid #ccc;">{{ result.household_num }}</td>
                  <td style="text-align:center;">{{ result.ua }}</td>
                  <td style="text-align:center; border-right: 1px solid #ccc;">{{ result.summer_ha }}</td>
                  <td style="text-align:center;">{{ result.eTGJ | setFractionDigit(1) }}</td>
                  <td style="text-align:center;">{{ result.eSTGJ | setFractionDigit(1) }}</td>
                  <td style="text-align:center;">{{ result.eTGJWO | setFractionDigit(1) }}</td>
                  <td style="text-align:center;">{{ result.eSTGJWO | setFractionDigit(1) }}</td>
                  <td style="text-align:center;">{{ result.bei | setFractionDigit(2) }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <v-pagination
            v-model="page"
            :length="pageCount"
            total-visible=7>
          </v-pagination>
        </v-col>
      </v-row>
      <v-row class="mx-0 mt-10 bl_fixedFooter">
        <v-btn
        @click="back"
        plain
        height="60"
        class="font-weight-bold text-h6 pl-0">
          <v-icon large>mdi-chevron-left</v-icon>
          戻る
        </v-btn>
      </v-row>
    </v-container>


    <div
      class="progress"
      v-show="progress">
      <v-progress-circular
          indeterminate
          :size="150"
          :width="15"
          color="grey"
          class="circular"
      ></v-progress-circular>
    </div>

  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { tabs, getTimestamp } from '@/util'

export default {
  components: { },

  data() {
    return {
      // ファイル取得の進行中表示の有無
      progress: false,
      // エラーファイル削除を確認するダイアログボックス表示の有無
      deleteDialog: false,
      //エラー確認ページ用のナビゲーション
      tabs: tabs,
      // ページネーション
      itemsPerPage: 500,
      page: 1,
      pageCount: 0,
    }
  },

  computed: {
    ...mapGetters([
        'selectedHouseholdType',
        'selectedReportType',
        'fileCnt',
        'householdCnt',
        'correctResults',
        'correctCnt',
        'averageBEI'
      ])
  },

  filters: {
    // 小数桁の最小数を設定する
    setFractionDigit(value, noOfDigits){
      const formatter = new Intl.NumberFormat('ja-JP', { 
        minimumFractionDigits: noOfDigits
      })
      return formatter.format(value)
    }
  },

  methods: {
    ...mapActions([
      'clearMessage',
      'getPdf',
      'update',
      'deleteFile'
    ]),

      // チェック通過済みファイル一覧のダウンロード
      downloadCorrectLists() {
        // ファイル名
        const timestamp = getTimestamp()
        const filename = 'チェック通過済みファイル一覧_' + this.selectedHouseholdType + '_' + this.selectedReportType + '_' + timestamp + '.csv'

        //CSVデータ
        let csvData = 'PDFファイル名,住宅タイプの名称,戸数,外皮平均熱貫流率 W/m²K,冷房期の平均日射熱取得率,設計一次エネルギー消費量 GJ,基準一次エネルギー消費量 GJ,' +
                          '設計一次エネルギー消費量（その他の設計一次エネルギー消費量を除く） GJ,基準一次エネルギー消費量（その他の基準一次エネルギー消費量を除く） GJ,BEI\n'
        this.correctResults.forEach(function callback(file) {
            csvData += 
                file.originalName + "," +
                file.household_name + "," +
                file.household_num + "," +
                file.ua + "," +
                file.summer_ha + "," +
                file.eTGJ + "," +
                file.eSTGJ + "," +
                file.eTGJWO + "," +
                file.eSTGJWO + "," +
                file.bei + "\n"
        })
        //BOMを付与する（Excelでの文字化け対策）
        const bom = new Uint8Array([0xef, 0xbb, 0xbf])
        //Blobでデータを作成する
        const blob = new Blob([bom, csvData], { type: "text/csv" })

        //IE10/11用(download属性が機能しないためmsSaveBlobを使用）
        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(blob, filename)
        //その他ブラウザ
        } else {
            //BlobからオブジェクトURLを作成する
            const url = (window.URL || window.webkitURL)
            const objectUrl = url.createObjectURL(blob)
            //ダウンロード用にリンクを作成する
            const download = document.createElement("a")
            //リンク先に上記で生成したURLを指定する
            download.href = objectUrl
            //download属性にファイル名を指定する
            download.download = filename
            //作成したリンクをクリックしてダウンロードを実行する
            download.click()
            //createObjectURLで作成したオブジェクトURLを開放する
            url.revokeObjectURL(objectUrl)
          }
      },

    // ファイル詳細画面へ遷移
    async toFileDetail(index){
      this.progress = true

      this.update({selectedIndex:index})
      await this.getPdf(index)

      if(this.selectedPdfSrc) {
        this.$router.push('/file-detail')
      }

      this.progress = false
    },

    // // エラーファイル削除を確認するダイアログボックスを表示する
    // async deleteErrorFile(index) {
    //   this.update({selectedIndex:index})
    //   this.deleteDialog = true
    // },

    // // エラーファイルを削除する
    // async deleteFileConfirm() {
    //   await this.deleteFile()
    //   this.deleteDialog = false
    // },

    // ファイルチェック画面へ遷移
    back() {
      this.clearMessage()
      this.$router.push('/file-check')
    }
  }
}
</script>

<style scoped>
.bl_resultListTable td{
    line-height: 1;
}
.bl_resultListTable.v-data-table > .v-data-table__wrapper > table > thead > tr > td{
  font-size: 0.8rem;
  padding-left:0.8em;
  padding-right:0.8em;
}

@media (max-width: 959px){
.uq_sm_flex_row{ 
  flex-direction: row !important;
  column-gap: 16px !important;
}
}
</style>